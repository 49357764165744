<template>
  <!--    编辑-->
  <el-dialog
    title="详情"
    :visible.sync="visiable"
    width="500px"
    class="addAlameBox"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <div style="max-height: 65vh" >
      <div class="list_top">
        <el-image
          class="headImg"
          :src="data.headImgShow"
          fit="cover"
        ></el-image>
        <div class="itemMess">
          <div class="itemLine">
            <div style="min-width: 60px">产品名称:</div>
            <div>{{ data.expertName }}</div>
          </div>

          <div class="itemLine">
            <div style="min-width: 60px">规格:</div>
            <div>{{ data.position }}</div>
          </div>
          <div class="itemLine">
            <div style="min-width: 60px">备注:</div>
            <div>{{ data.goodAt }}</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
export default {
  props: {
    visiable: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {};
  },
  mounted() {
    // //console.log(this.record, "record");
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>
  
  <style scoped>
.list_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.itemMess {
  width: calc(100% - 120px);
}

.itemLine {
  width: 100%;
  display: flex;
  font-size: 14px;
  line-height: 24px;
}
.text_ell {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headImg {
  width: 100px;
  height: 100px;
}
</style>