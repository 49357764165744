<template>
  <!--    编辑-->
  <el-dialog
    :title="formTitle"
    :visible.sync="messageBox"
    width="70vw"
    class="addAlameBox"
    :close-on-click-modal="false"
  >
    <div style="max-height: 65vh;">
      <FormBox
        ref="formRef"
        :showFlag="showFlag"
        :formArr="formArr"
        :record="addForm"
        @saveForm="saveForm"
        @handleCancel="handleCancel"
        :fileListModify="fileListModify"
        :rules="rules"
      ></FormBox>
    </div>
  </el-dialog>
</template>

<script>
import FormBox from "@/components/lxw/Form/index.vue";
export default {
  components: { FormBox },
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      formArr: [
        {
          label: "产品名称",
          key: "inputName",
          type: "Input",
        },
        {
          label: "规格",
          key: "unit",
          type: "Input",
        },
        {
          label: "备注",
          key: "remarks",
          type: "Textarea",
        },
        {          
          label: "图片",
          key: "inputImg",
          type: "FileUpload",
          valueType: 'string',
          width: "100%", 
          fileType: ["jpg", "jpeg", "png"]
        }    
      ],
      addForm: {},
      rules: {
        // 产品名称
        inputName: [
          {required: true, message: "产品名称不可为空", trigger: ["blur", "change"]},
        ],
        // 规格
        unit: [
          {required: true, message: "规格不可为空", trigger: ["blur", "change"]},
        ],
      },
      fileListModify: []
    };
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
  
        this.addForm = newVal;
        if(newVal.inputImg){
          this.$getBlob("/fileops/show", {
            fileName: newVal.inputImg,
          }).then((res) => {
            let fileNames = res.config.params.fileName;
            let fileData = fileNames.split(".")[1];
            let blob = new Blob([res.data],{type: 'image/'+ fileData})
            let url = window.URL.createObjectURL(blob);
            this.fileListModify = [{name: newVal.inputImg, url: url, blob, fileData}];
          })
        }else{
          this.fileListModify = [];
        }
        

      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // //console.log(this.record, "record");
  },
  methods: {
    getFileListAndForm(obj) {
      if (obj && obj.fileList) {
        const { fileList } = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
            item.response &&
            item.response.data &&
            item.response.data.fileName
          ) {
            list.push(item.response.data.fileName);
          }
        });
        this.filesList = list;
      }
    },

    // 确认提交
    saveForm(param) {

      let url = "/inputInfo/save";
      delete param.pushRowsBtn;
      if(!param.status){
        param.status = 0;
      }
      // if(this.formTitle == '新增'){
      //   delete this.addForm.id;
      // }

      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "新增") {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 重置字段
    resetForm() {
      this.$refs["formRef"].resetForm();
    },

    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

</style>